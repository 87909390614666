import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FilterableArray } from '../../components/search-grid/models/filterable-array.model';
import {
    CotizacionesConfig,
    CotizacionesPlazo,
    CotizacionesCurrency,
    CotizacionesDisplayMode,
    CotizacionesSubRoute,
    CotizacionesMercado,
    CotizacionesPrecios
} from './../../shared/models/cotizaciones-config.model';
import { BzStorageService } from '@balanz/services';
import Plazos from '../../shared/constants/plazos.json';

/**
 * TODO: Hacer dinamico cuando exista el api del backend.
 */
const COTIZACIONES_ROUTES: CotizacionesSubRoute[] = [
    { id: 1, label: 'acciones' },
    { id: 1, label: 'bonos' }
];

/**
 * TODO: Hacer dinamico cuando exista el api del backend.
 */
const CURRENCIES: CotizacionesCurrency[] = [
    { text: 'Pesos', value: 'Pesos', checked: true },
    { text: 'Dólar MEP', value: 'Dolar MEP', checked: true },
    { text: 'Dólar Cable', value: 'Dolar Cable', checked: true }
];

/**
 * TODO: Hacer dinamico cuando exista el api del backend.
 */
const PLAZOS: CotizacionesPlazo[] = [
    { text: '48 hs', value: '48hs' },
    { text: '24 hs', value: '24hs' },
    { text: 'CI', value: 'CI' }
];

/**
 * TODO: Hacer dinamico cuando exista el api del backend.
 */
const MERCADOBONOS: CotizacionesMercado[] = [
    {
        title: 'global.components.searchGrid.mercadoBonos.soberanos',
        value: 23,
        checked: true
    },
    {
        title: 'global.components.searchGrid.mercadoBonos.subsoberanos',
        value: 24,
        checked: false
    }
];

const MERCADOACCIONES: CotizacionesMercado[] = [
    {
        title: 'S&P MERVAL',
        value: 1,
        checked: true
    },
    {
        title: 'S&P / BYMA Índice General',
        value: 4,
        checked: false
    },
    {
        title: 'S&P / Merval Argentina',
        value: 3,
        checked: false
    }
];

const MERCADOACCIONESINT: CotizacionesMercado[] = [
    {
        title: 'NYSE',
        value: 60,
        checked: true
    },
    {
        title: 'NASDAQ',
        value: 61,
        checked: false
    },
    {
        title: 'ETF',
        value: 62,
        checked: false
    }
];

const MERCADOLETRAS: CotizacionesMercado[] = [
    {
        title: 'LETES',
        value: 25,
        checked: true
    },
    {
        title: 'LECAPS',
        value: 26,
        checked: false
    },
    {
        title: 'LECERS',
        value: 29,
        checked: false
    },
    {
        title: 'LELINKS',
        value: 32,
        checked: false
    }
];

const MERCADOFUTUROS: CotizacionesMercado[] = [
    {
        title: 'ROFEX',
        value: 9,
        checked: true
    },
    {
        title: 'BYMA',
        value: 31,
        checked: false
    },
    {
        title: 'MATBA',
        value: 33,
        checked: false
    }
];

const MERCADOINTERNACIONAL: CotizacionesMercado[] = [
    {
        title: 'NYSE',
        value: 19,
        checked: true
    },
    {
        title: 'NASDAQ',
        value: 21,
        checked: false
    },
    {
        title: 'ETF',
        value: 22,
        checked: false
    }
];

const PRECIOS: CotizacionesPrecios[] = [
    {
        text: 'Mostar con precio',
        value: 'mostarConPrecio',
        checked: true
    }
];

let SECTORES: any[] = [];

let INDUSTRIAS: any[] = [];
/**
 * Servicio encargado de administrar la informacion entre las
 * subrutas de CotizacionesModule.
 */
@Injectable()
export class CotizacionesConfigService {
    private _config!: CotizacionesConfig;
    private _displayModeChange = new BehaviorSubject<CotizacionesDisplayMode>('table');
    plazosFondos;
    perfilesFondos;
    plazoDefault;
    /**
     * Obtener configuracion actual de las cotizaciones.
     */
    get config(): CotizacionesConfig {
        return this._config;
    }

    constructor(private _storageService: BzStorageService) {
        this.resetConfig();
    }

    /**
     * Resetea la configuracion de cotizaciones.
     */
    resetConfig(): void {
        const displayMode = this._storageService.get('cotDisplayMode', true);
        this._config = {
            plazos: this._cloneArray(PLAZOS),
            plazoDefault: { ...PLAZOS[0] },
            displayMode: (displayMode as CotizacionesDisplayMode) || 'table',
            subroutes: this._cloneArray(COTIZACIONES_ROUTES),
            currencies: this._cloneArray(CURRENCIES),
            search: '',
            eventoptfrom: '',
            selectedPlazo: { ...PLAZOS[0] },
            precios: this._cloneArray(PRECIOS),
            industrySector: [],
            industrias: []
        };
    }

    /**
     * Actualiza el valor del plazo seleccionado por el usuario.
     */
    setPlazo(newPlazo: CotizacionesPlazo | string): void {
        if (typeof newPlazo === 'string') {
            const finded = PLAZOS.find(p => p.value.toLowerCase() === newPlazo.toLowerCase());
            if (finded) {
                this._config.selectedPlazo = finded;
            }
        } else {
            this._config.selectedPlazo = newPlazo;
        }
    }

    /**
     * Resetea el parametro de plazos.
     */
    resetPlazo(): void {
        this._config.selectedPlazo = this._config.plazoDefault;
    }

    /**
     * Actualiza el valor de la busqueda del usuario.
     */
    setSearch(newSearch: string): void {
        this._config.search = newSearch;
    }

    /**
     * Actualiza el valor de la busqueda del usuario.
     */
    setEvenTopFrom(newEventTopFrom: string): void {
        this._config.eventoptfrom = newEventTopFrom;
    }

    /**
     * Actualiza las monedas que estan o no checkeadas.
     */
    checkCurrency(id: string): void {
        const finded = this._config.currencies.find((auxCurrency: CotizacionesCurrency) => auxCurrency.value === id);
        if (finded) {
            finded.checked = !finded.checked;
        }
    }

    /**
     * Resetea el valor de las monedas.
     */
    resetCurrencies(): void {
        this._config.currencies = this._cloneArray(CURRENCIES);
    }

    /**
     * Observable que informa cambios del modo de display del contenido
     * de cotizaciones.
     */
    onDisplayModeChanges(): Observable<CotizacionesDisplayMode> {
        return this._displayModeChange.asObservable();
    }

    /**
     * Intercambia el display de cotizaciones entre card y table.
     */
    toggleDisplayMode(): void {
        this._config.displayMode = this._config.displayMode === 'table' ? 'card' : 'table';
        this._storageService.set('cotDisplayMode', this._config.displayMode, true);
        this._displayModeChange.next(this._config.displayMode);
    }

    // Devuelve un array del tipo filterableArray con todos los filtros necesarios para cada seccion de cotizacion.

    getFilterableArrays(type: string): FilterableArray[] {
        const filters: FilterableArray[] = [];

        const plazosFilter: FilterableArray = {
            id: 'Plazos',
            array: this.config.plazos,
            key: 'text',
            value: 'value',
            filterType: 'radio',
            label: 'global.components.searchGrid.labels.plazos'
        };
        const monedasFilter: FilterableArray = {
            id: 'Monedas',
            array: this.config.currencies,
            key: 'text',
            value: 'value',
            filterType: 'checkbox',
            label: 'global.components.searchGrid.labels.monedas'
        };
        const precioFilter: FilterableArray = {
            id: 'Precios',
            array: this.config.precios,
            key: 'text',
            value: 'value',
            filterType: 'checkbox',
            label: 'global.components.searchGrid.labels.precios'
        };
        const sector: FilterableArray = {
            id: 'industrySector',
            array: SECTORES,
            key: 'text',
            value: 'value',
            filterType: 'radio',
            label: 'Sector'
        };
        const industrias: FilterableArray = {
            id: 'industryGroup',
            array: INDUSTRIAS,
            key: 'text',
            value: 'value',
            filterType: 'radio',
            label: 'Industria'
        };
        switch (type) {
            case 'AccionesInt':
                const mercadosAccionesInt: FilterableArray = {
                    id: 'Mercado',
                    array: MERCADOACCIONESINT,
                    key: 'title',
                    value: 'value',
                    filterType: 'radio',
                    label: 'global.components.searchGrid.labels.mercado'
                };
                filters.push(mercadosAccionesInt);
                filters.push(precioFilter);
                filters.push(sector);
                filters.push(industrias);
                return filters;
                break;
            case 'Acciones':
                const mercadosAcciones: FilterableArray = {
                    id: 'Mercado',
                    array: MERCADOACCIONES,
                    key: 'title',
                    value: 'value',
                    filterType: 'radio',
                    label: 'global.components.searchGrid.labels.mercado'
                };
                filters.push(mercadosAcciones);
                filters.push(plazosFilter);
                filters.push(precioFilter);
                filters.push(sector);
                filters.push(industrias);
                return filters;
                break;
            case 'Bonos':
                const mercadosBonos: FilterableArray = {
                    id: 'Mercado',
                    array: MERCADOBONOS,
                    key: 'title',
                    value: 'value',
                    filterType: 'radio',
                    label: 'global.components.searchGrid.labels.mercado'
                };

                filters.push(mercadosBonos);
                filters.push(plazosFilter);
                filters.push(monedasFilter);
                filters.push(precioFilter);
                filters.push(sector);
                filters.push(industrias);
                return filters;
                break;
            case 'Cedears':
                filters.push(monedasFilter);
                filters.push(precioFilter);
                filters.push(sector);
                filters.push(industrias);
                return filters;
                break;

            case 'Letras':
                const mercadosLetras: FilterableArray = {
                    id: 'Tipo',
                    array: MERCADOLETRAS,
                    key: 'title',
                    value: 'value',
                    filterType: 'radio',
                    label: 'global.components.searchGrid.labels.tipo'
                };
                filters.push(plazosFilter);
                filters.push(mercadosLetras);
                filters.push(monedasFilter);
                filters.push(precioFilter);
                filters.push(sector);
                filters.push(industrias);
                return filters;
                break;
            case 'Corporativos':
                filters.push(plazosFilter);
                filters.push(monedasFilter);
                filters.push(precioFilter);
                filters.push(sector);
                filters.push(industrias);
                return filters;
                break;

            case 'Fondos':
                filters.push(monedasFilter);
                if (this.plazosFondos) {
                    filters.push(this.plazosFondos);
                }
                if (this.perfilesFondos) {
                    filters.push(this.perfilesFondos);
                }
                return filters;
                break;

            case 'Futuros':
                const mercadosFuturos: FilterableArray = {
                    id: 'Tipo',
                    array: MERCADOFUTUROS,
                    key: 'title',
                    value: 'value',
                    filterType: 'radio',
                    label: 'global.components.searchGrid.labels.tipo'
                };
                filters.push(mercadosFuturos);
                filters.push(precioFilter);
                return filters;
                break;

            case 'Cauciones':
                monedasFilter.array = monedasFilter.array.filter(m => m.value === 'Pesos' || m.value === 'Dolar MEP');
                filters.push(monedasFilter);
                filters.push(precioFilter);
                return filters;
                break;

            case 'Internacional':
                const mercadosInternacional: FilterableArray = {
                    id: 'Mercado',
                    array: MERCADOINTERNACIONAL,
                    key: 'title',
                    value: 'value',
                    filterType: 'radio',
                    label: 'global.components.searchGrid.labels.mercado'
                };
                filters.push(mercadosInternacional);
                filters.push(precioFilter);
                filters.push(sector);
                filters.push(industrias);
                return filters;

            case 'Opciones':
                filters.push(precioFilter);
                filters.push(sector);
                filters.push(industrias);
                return filters;

            case 'Treasuries':
                filters.push(precioFilter);
                return filters;

            case 'BonosLatam':
                filters.push(precioFilter);
                return filters;

            default:
                return filters;
                break;
        }
    }
    getPlazosFondos(plazos: any[]) {
        this.plazosFondos = {
            id: 'Plazos',
            array: plazos,
            key: 'label',
            value: 'value',
            filterType: 'checkbox',
            label: 'Plazo Rescate'
        };
    }
    getTipoPerfilesFondos(perfiles: any[]) {
        this.perfilesFondos = {
            id: 'tipoPerfiles',
            array: perfiles,
            key: 'value',
            value: 'value',
            filterType: 'checkbox',
            label: 'Perfil inversor'
        };
    }
    /**
     * Devuelve una copia exacta. Esto es una solucion para que no se
     * modifiquen los objetos de los arrays originales de la config.
     */
    private _cloneArray(array: any[]): any[] {
        return array.map(a => ({ ...a }));
    }
    getPlazoDefault(plazoDefault) {
        const plazoFindedByValue: any = Plazos.find(p => plazoDefault === p.valor);
        this.plazoDefault = PLAZOS.find(
            p => plazoFindedByValue.descripcion.toLowerCase() === p.value.toLocaleLowerCase()
        );
        if (this.plazoDefault) {
            this._config.plazoDefault = this.plazoDefault;
            this._config.selectedPlazo = this.plazoDefault;
        }
    }
    getPlazosByInstrument(plazos) {
        const plazosObjs = [];
        plazos.forEach(plazo => {
            const plazoEncontrado = Plazos.find(p => p.valor === plazo);
            if (plazoEncontrado) {
                plazosObjs.push(plazoEncontrado);
            }
        });
        const arrayPlazos = [];
        plazosObjs.forEach(plazo => {
            const plazoEncontrado = PLAZOS.find(p => p.value.toLocaleLowerCase() === plazo.descripcion.toLowerCase());
            if (plazoEncontrado) {
                arrayPlazos.push(plazoEncontrado);
            }
        });
        if (arrayPlazos) {
            this._config.plazos = this._cloneArray(arrayPlazos);
        }
    }

    getIndustriesSector(cotizaciones) {
        SECTORES = [];
        this._config.industrySector = [];
        SECTORES.push({
            text: 'Todos',
            value: 'Todos',
            checked: true
        });
        this._config.industrySector.push({
            text: 'Todos',
            value: 'Todos',
            checked: true
        });
        const industries = cotizaciones.map(cot => cot.industrySector);
        industries.filter(ind => {
            if (ind) {
                if (!this._config.industrySector.includes(ind)) {
                    if (this._config.industrySector.find(i => i === ind)) {
                        return;
                    } else {
                        this._config.industrySector.push(ind);
                        SECTORES.push({
                            text: ind,
                            value: ind
                        });
                    }
                }
            }
        });
    }
    getIndusrias(sector, cotizaciones) {
        INDUSTRIAS = [];
        this._config.industrias = [];
        INDUSTRIAS.push({
            text: 'Todos',
            value: 'Todos',
            checked: true
        });
        this._config.industrias.push({
            text: 'Todos',
            value: 'Todos',
            checked: true
        });
        const industriesBySector = cotizaciones.filter(cot => cot.industrySector === sector);
        const industries = industriesBySector.map(cot => cot.industryGroup);
        industries.filter(ind => {
            if (ind) {
                if (!this._config.industrias.includes(ind)) {
                    if (this._config.industrias.find(i => i === ind)) {
                        return;
                    } else {
                        this._config.industrias.push(ind);
                        INDUSTRIAS.push({
                            text: ind,
                            value: ind
                        });
                    }
                }
            }
        });
    }
}
