import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { DeviceService } from './../../core/services/device.service';
import { AuthService } from './../../core/services/auth.service';
import { EnvironmentService } from './../../core/services/environment.service';
import momentTimezone from 'moment-timezone';
import moment from 'moment';
import { SwUpdate } from '@angular/service-worker';
import { sesionCanAccess } from 'src/app/utils/validacion-sesion';
import { BzUserBehaviourService } from '@balanz/services';

@Injectable({
    providedIn: 'root'
})
export class TrackerService {
    private _currentPage!: string;
    private checkInPage!: moment.Moment;
    private checkOutPage!: moment.Moment;

    private needsRefresh = false;

    constructor(
        private router: Router,
        private device: DeviceService,
        private authService: AuthService,
        private _envService: EnvironmentService,
        private activeRoute: ActivatedRoute,
        private swUpdate: SwUpdate,
        private _userBehaviourService: BzUserBehaviourService
    ) {
        this._registerEventChagenRouter();
        momentTimezone().tz(momentTimezone.tz.guess());
    }

    private _registerEventChagenRouter(): void {
        this.router.events.subscribe(e => {
            if (e instanceof NavigationStart) {
                if (this.needsRefresh) {
                    this.needsRefresh = false;
                    this.swUpdate.activateUpdate().then(successfullyActivated => {
                        if (successfullyActivated) {
                            window.location.href = e.url;
                        }
                    });
                } else if (this.swUpdate.isEnabled) {
                    this.swUpdate.checkForUpdate().then(newVersionFound => {
                        if (newVersionFound) {
                            this.needsRefresh = this._envService.env.automaticUpdates;
                        }
                    });
                }
            }
            if (e instanceof NavigationEnd) {
                if (this._currentPage) {
                    this.checkOutPage = momentTimezone();
                    const content = {
                        device: this.device.currentDevice(),
                        url: this._currentPage,
                        navigated_to: e.url,
                        module: 'APP',
                        check_in: this.checkInPage.format(),
                        check_out: this.checkOutPage.format(),
                        time: this.checkOutPage.diff(this.checkInPage, 'millisecond'),
                        action_from: this.activeRoute.snapshot.queryParams['action_from'] || null
                    };

                    if (sesionCanAccess(this.authService)) {
                        this._userBehaviourService.sendEvent({
                            category: 'navigations',
                            name: 'navigations.v2',
                            description: 'Log navigations by user on frontend v2',
                            properties: {
                                type: 'navigated'
                            },
                            content: content
                        });
                    }
                }

                this._currentPage = e.url;
                this.checkInPage = momentTimezone();
            }
        });
    }

    convertObjToString(object): string {
        if (typeof object === 'object') {
            return Object.entries(object)
                .map(item => `${item[0]}:${item[1]}`)
                .join(';');
        } else if (typeof object !== 'string') {
            return '';
        }
    }
}
