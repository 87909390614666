import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LikedInstrument } from 'src/app/shared/models/liked-instruments';
import { EnvironmentService } from '../services/environment.service';
import { LikeUnlikePayload } from 'src/app/shared/models/like-unlike-payload.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SocialService {
    private _socialBasePath = this._envService.env.baseUrlInsights + '/social/';

    constructor(private http: HttpClient, private _envService: EnvironmentService) {}

    getInstrumentLikes(securities: string[]): Observable<LikedInstrument[]> {
        return this.http
            .post<any>(`${this._socialBasePath}actionbyuser?actionType=1`, { instruments: securities })
            .pipe(map(response => response.instruments));
    }

    likeInstrument(payload: LikeUnlikePayload): Observable<any> {
        return this.http.post<any>(`${this._socialBasePath}action`, payload, {
            params: { avoidAuthRedirect: true }
        });
    }
    unlikeInstrument(payload: LikeUnlikePayload): Observable<any> {
        return this.http.delete<any>(
            `${this._socialBasePath}action?&action_type=1&ticker=${payload.ticker}&security_id=${payload.security_id}`,
            {
                params: { avoidAuthRedirect: true }
            }
        );
    }
}
