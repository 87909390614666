import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { EnvironmentService } from './core/services/environment.service';
import { ViewService } from './core/services/view.service';
import { filter } from 'rxjs/operators';
import { AppTheme, ThemeService } from './core/services/theme.service';
import { CuentaService } from './core/clients/cuenta.service';
import { AuthService } from './core/services/auth.service';

import { BzStorageService, BzUserBehaviourService } from '@balanz/services';
import { v4 as uuidv4 } from 'uuid';
import { VERSION } from 'src/environments/version';
import unwantedFieldsInEvents from 'src/app/shared/constants/unwantedFieldsInEvents.json';
@Component({
    selector: 'app-root',
    template: `
        <!-- modales -->
        <div class="position-absolute w-100 h-100 pe-none" [style.zIndex]="99999">
            <app-modal-v2></app-modal-v2>
        </div>
        <div *ngIf="showRefresh">
            <app-refresh-modal (updateEvent)="doUpdate()" (closeEvent)="doClose()"></app-refresh-modal>
        </div>
        <!-- general -->
        <router-outlet></router-outlet>
    `
})
export class AppComponent implements OnInit {
    showRefresh = false;

    constructor(
        private _translateService: TranslateService,
        private _swUpdate: SwUpdate,
        private _envService: EnvironmentService,
        private _storageService: BzStorageService,
        private _viewService: ViewService,
        private _themeService: ThemeService,
        private _cuentaService: CuentaService,
        private authService: AuthService,
        private _userBehaviourService: BzUserBehaviourService
    ) {
        document.addEventListener('visibilitychange', () => {
            this._viewService.setViewActive(document.visibilityState === 'visible');
        });
        this._userBehaviourService.config = {
            url: this._envService.env.BaseUrlLoggerService,
            enabled: true,
            version: 'FrontEnd ' + VERSION.version + ' ' + VERSION.hash,
            unwantedFieldsInEvents: unwantedFieldsInEvents
        };
        /* MIGRACION DEL STORAGE, BORRAR AL TERMINAR */
        const fieldsToMigrate = [
            { key: 'bdid', localStorage: true },
            { key: 'personalized_columns', localStorage: true },
            { key: 'cotDisplayMode', localStorage: true },
            { key: 'hideMoney', localStorage: true },
            { key: 'theme', localStorage: true },
            { key: 'last_searched', localStorage: true },
            { key: 'campaign_id', localStorage: true },
            { key: 'returnUrl', localStorage: false },
            { key: 'continue', localStorage: false },
            { key: 'forward', localStorage: false },
            { key: 'forwardParams', localStorage: false },
            { key: 'sequence_id', localStorage: false },
            { key: 'pendingEvents', localStorage: false },
            { key: 'behaviour_id', localStorage: false }
        ];
        fieldsToMigrate.forEach(field => {
            const value = this._storageService.get(field.key, field.localStorage, true);
            if (value) {
                this._storageService.set(field.key, value, field.localStorage);
                this._storageService.remove(field.key, field.localStorage, true);
            }
        });
        /* MIGRACION DEL STORAGE, BORRAR AL TERMINAR */
    }

    ngOnInit(): void {
        window.addEventListener('message', event => {
            if (event.origin !== 'http://localhost:4200') {
                return;
            }
            const { functionName, messageData } = event.data;
            switch (functionName) {
                case 'onThemeChange':
                    this._changeTheme(messageData);
                    break;
                case 'onAccountChange':
                    this._cuentaService.setCuentaSeleccionada(messageData);
                    break;
            }
        });
        const storedBalanzDeviceId = this._storageService.get('bdid', true);
        if (!storedBalanzDeviceId) {
            this._storageService.set('bdid', uuidv4(), true);
        }

        this._configTranslate();
        if (this._swUpdate.isEnabled) {
            this._swUpdate.versionUpdates
                .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
                .subscribe(() => (this.showRefresh = this._envService.env.showUpdateDialog));
            this._swUpdate.checkForUpdate();
        }
    }

    doClose(): void {
        this.showRefresh = false;
    }

    doUpdate(): void {
        this._swUpdate.activateUpdate().then(() => document.location.reload());
    }

    private _configTranslate(): void {
        this._translateService.addLangs(this._envService.env.translateConfig.languages);
        this._translateService.setDefaultLang(this._envService.env.translateConfig.defaultLang);
        this._translateService.use(this._envService.env.translateConfig.defaultLang);
    }

    @HostListener('window:message', ['$event'])
    onMessage(event: MessageEvent) {
        if (event.data.themeChange) {
            this._changeTheme(event.data.themeChange);
        }
        if (event.data.accountChange) {
            this._cuentaService.setCuentaSeleccionada(event.data.accountChange);
        }
    }
    private _changeTheme(event) {
        this._themeService.changeTheme(event, this.authService.getCurrentUser().idPersona);
    }
}
